.side-bar {
    bottom: 0;
    top: 0;
    left: 0;
    width: 239px;
    background: linear-gradient(123.06deg, rgba(255, 255, 255, 0.4) 16%, rgba(255, 255, 255, 0.1) 72.54%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.08);
}

.side-bar-btn {
    height: 56px;
    width: 100%;
    background: rgba(122, 0, 230, 1);
    align-items: center;
    padding: 16px;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    border-color: rgba(122, 0, 230, 1);
}