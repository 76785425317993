.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
}

.delete-modal {
  border-radius: 20px;
  height: 200px;
  width: 500px;
}

.edit-user-modal {
  border-radius: 20px;
  height: 500px;
  width: 500px;
}