.delete-users-btn {
    border-radius: 20px;
    background: #7A00E6;
    width: 156px;
    height: 52px;
    border: none;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.08);
}

.cancel-delete-btn {
    border-radius: 20px;
    width: 156px;
    height: 52px;
    border: none;
    background: linear-gradient(123.06deg, rgba(255, 255, 255, 0.4) 16%, rgba(255, 255, 255, 0.1) 72.54%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.08);
}

.logout-btn {
    border-radius: 20px;
    width: 100%;
    height: 52px;
    border: none;
    background: linear-gradient(123.06deg, rgba(255, 255, 255, 0.4) 16%, rgba(255, 255, 255, 0.1) 72.54%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.08);
}